<template>
  <div class="card">
    <div class="card-header bg-white">
      <h6 class="card-title font-weight-semibold">Tambah Edukasi {{ $route.query.viewType == 'EDUKASI_HARIAN' ? 'Harian' : '' }}</h6>

      <hr class="mb-0" />
    </div>
    <validation-observer ref="VFormEHFisioterapi" v-if="$route.query.viewType == 'EDUKASI_HARIAN'">
      <b-form @submit.prevent="doSubmit('VFormEHFisioterapi', 'add-edukasi-harian')">
        <div class="card-body">
          <div class="row gx-2">
            <div class="col-md-4">
              <label>Tanggal Edukasi <span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <div class="input-group">
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                  </div>
                  <date-picker v-model="row.education_date" :default-value="row.education_date" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="-- Tanggal Edukasi --"></date-picker>
                </div>
                <VValidate name="Tanggal Edukasi" v-model="row.education_date" :rules="mrValidation.education_date" />
              </div>
            </div>            
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <label>Uraian Penjelasan <span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <b-form-textarea rows="6" v-model="row.description"></b-form-textarea>
              </div>
              <VValidate name="Uraian Penjelasan" v-model="row.description" :rules="mrValidation.description" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Penerima Penjelasan</h6>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Hubungan dengan pasien <span class="text-danger">*</span></label>
                <b-form-input v-model="row.relation_patient" />

                <VValidate name="Hubungan dengan pasien" v-model="row.relation_patient" :rules="mrValidation.relation_patient" />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Nama <span class="text-danger">*</span></label>
                <b-form-input v-model="row.receiver_name" />

                <VValidate name="Nama" v-model="row.receiver_name" :rules="mrValidation.receiver_name" />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Tanda Tangan</h6>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Edukator <span class="text-danger">*</span></label>                

                <div class="signing-element mt-1" data-target-id="ttdEdukator">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdEdukator" @onend="onEnd('ttdEdukator')" />
                  <VValidate name="Tanda Tangan Edukator" v-model="row.ttd_edukator" :rules="mrValidation.ttd_edukator" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdEdukator','ttd_edukator')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdEdukator','ttd_edukator')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Penerima Penjelasan <span class="text-danger">*</span></label>                
                <div class="signing-element mt-1" data-target-id="ttdReceiver">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdReceiver" @onend="onEnd('ttdReceiver')" />
                  <VValidate name="Tanda Penerima Penjelasan" v-model="row.ttd_receiver" :rules="mrValidation.ttd_receiver" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdReceiver','ttd_receiver')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdReceiver','ttd_receiver')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" class="btn" @click="$router.back()">Kembali</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <validation-observer ref="VFormETFisioterapi" v-else>
      <b-form @submit.prevent="doSubmit('VFormETFisioterapi', 'add-edukasi-integrasi')">
        <div class="card-body">
          <div class="row gx-2">
            <div class="col-md-4">
              <label>Tanggal Edukasi <span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <div class="input-group">
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                  </div>
                  <date-picker v-model="row.education_date" :default-value="row.education_date" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="-- Tanggal Edukasi --"></date-picker>
                </div>
                <VValidate name="Tanggal Edukasi" v-model="row.education_date" :rules="mrValidation.education_date" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Durasi Waktu<strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input type="text" v-model="row.duration" />
                  <div class="input-group-append"><span class="input-group-text">menit</span></div>
                </div>

                <VValidate name="Durasi" v-model="row.duration" :rules="mrValidation.duration" />
              </div>
            </div>
          </div>

          <div class="row g-3">
            <div class="col-md-4">
              <b-form-group>
                <label>
                  Materi Edukasi <span class="text-danger">*</span>
                </label>

                <b-form-checkbox-group
                  v-model="row.materi"
                  name="`materi`"
                >
                  <b-form-checkbox 
                    v-for="(v, k) in $parent.mrMateri" :key="k" 
                    :value="v"
                    :id="`materi${k}`"
                    class="mb-2"
                  >
                    {{ v.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <VValidate 
                  name="Materi Edukasi"
                  :value="(row.materi||[]).length > 0 ? true : null"
                  :rules="mrValidation.materi"
                />
              </b-form-group>

              <b-form-group v-if="isQuestionMateri">
                <label>Pertanyaan</label>
                <b-form-textarea rows="6" v-model="row.question"></b-form-textarea>

                <VValidate
                  name="Pertanyaan"
                  v-model="row.question"
                  rules="required"
                />
              </b-form-group>
            </div>

            <div class="col-md-8">
              <b-form-group class="mb-2">
                <label>
                  Tingkat Pemahaman Awal <span class="text-danger">*</span>
                </label>
                <b-form-radio-group v-model="row.pemahaman_awal" :options="$parent.mrPemahamanAwal" text-field="label" value-field="value"></b-form-radio-group>
                <VValidate name="Tingkat Pemahaman Awal" v-model="row.pemahaman_awal" :rules="mrValidation.pemahaman_awal" />
              </b-form-group>

              <b-form-group class="mb-2">
                <label>
                  Metode Edukasi <span class="text-danger">*</span>
                </label>

                <b-form-checkbox-group
                  v-model="row.metode"
                  name="`metode`"
                >
                  <b-form-checkbox 
                    v-for="(v, k) in $parent.mrMetode" :key="k" 
                    :value="v"
                    :id="`metode${k}`"
                    class="mb-2"
                  >
                    {{ v.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <VValidate 
                  name="Metode Edukasi"
                  :value="(row.metode||[]).length > 0 ? true : null"
                  :rules="mrValidation.metode"
                />
              </b-form-group>

              <b-form-group>
                <label>
                  Sarana Edukasi <span class="text-danger">*</span>
                </label>

                <b-form-checkbox-group
                  v-model="row.sarana"
                  name="`sarana`"
                >
                  <b-form-checkbox 
                    v-for="(v, k) in $parent.mrSarana" :key="k" 
                    :value="v"
                    :id="`sarana${k}`"
                    class="mb-2"
                  >
                    {{ v.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <VValidate 
                  name="Sarana Edukasi"
                  :value="(row.sarana||[]).length > 0 ? true : null"
                  :rules="mrValidation.sarana"
                />
              </b-form-group>
            </div>
          </div>          

          <div class="row">
            <div class="col-md-12">
              <hr />

              <label>
                Evaluasi <span class="text-danger">*</span>
              </label>
              <b-form-group>
                <b-form-radio-group v-model="row.evaluasi" :options="$parent.mrEvaluasi" text-field="label" value-field="value"></b-form-radio-group>
                <VValidate name="Evaluasi" v-model="row.evaluasi" :rules="mrValidation.evaluasi" />
              </b-form-group>              
            </div>

            <div class="col-md-5" v-if="row.evaluasi == 'RE_EDUKASI'">
              <label>Tanggal Re-edukasi <span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <div class="input-group">
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                  </div>
                  <date-picker v-model="row.reschedule_date" :default-value="row.reschedule_date" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" placeholder="-- Tanggal Edukasi --"></date-picker>
                </div>
                <VValidate name="Tanggal Re-edukasi" v-model="row.reschedule_date" :rules="mrValidation.reschedule_date" />
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <h6 class="font-weight-semibold text-info">Sasaran</h6>
              <label>
                Edukasi diterima pasien? <span class="text-danger">*</span>
              </label>
              <b-form-group>
                <b-form-radio-group v-model="row.sasaran" :options="$parent.mrSasaran" text-field="label" value-field="value"></b-form-radio-group>
                <VValidate name="Sasaran" v-model="row.sasaran" :rules="mrValidation.sasaran" />
              </b-form-group>
            </div>

          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Penerima Penjelasan</h6>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Hubungan dengan pasien <span class="text-danger">*</span></label>
                <b-form-input v-model="row.relation_patient" />

                <VValidate name="Hubungan dengan pasien" v-model="row.relation_patient" :rules="mrValidation.relation_patient" />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Nama <span class="text-danger">*</span></label>
                <b-form-input v-model="row.receiver_name" />

                <VValidate name="Nama" v-model="row.receiver_name" :rules="mrValidation.receiver_name" />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Tanda Tangan</h6>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Edukator <span class="text-danger">*</span></label>                

                <div class="signing-element mt-1" data-target-id="ttdEdukator">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdEdukator" @onend="onEnd('ttdEdukator')" />
                  <VValidate name="Tanda Tangan Edukator" v-model="row.ttd_edukator" :rules="mrValidation.ttd_edukator" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdEdukator','ttd_edukator')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdEdukator','ttd_edukator')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Sasaran <span class="text-danger">*</span></label>                
                <div class="signing-element mt-1" data-target-id="ttdSasaran">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdSasaran" @onend="onEnd('ttdSasaran')" />
                  <VValidate name="Tanda Tangan Sasaran" v-model="row.ttd_sasaran" :rules="mrValidation.ttd_sasaran" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdSasaran','ttd_sasaran')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdSasaran','ttd_sasaran')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" class="btn" @click="$router.back()">Kembali</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  import Formatter from '@/libs/Formatter.js'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  const _ = global._
  export default {
    props: {},
    components: {
      DatePicker
    },
    data() {
      return {
      }
    },
    mounted() {
      if(this.row.ttd_edukator) this.setSignature(this.row.ttd_edukator, 'ttdEdukator')
      if(this.row.ttd_receiver) this.setSignature(this.row.ttd_receiver, 'ttdReceiver')
    },
    methods: {
      setSignature(signature, refs) {
        setTimeout(() => {
          if (signature) this.$refs[refs].fromDataURL(signature)
        }, 1000)
      },
      undoTTD(refs, field) {
        this.$refs[refs].clearSignature()
        // this.row[field] = null
        this.$set(this.row, field, null)
      },
      output(refs, field) {
        let {
          isEmpty,
          data
        } = this.$refs[refs].saveSignature()
        if (!isEmpty) {
          // this.$parent.row[field] = data
          this.$set(this.row, field, data)
        }
      },
      doSubmit(ref, typeSubmit) {
        this.$refs[ref].validate().then(success => {
          if (!success) return false
          this.$swal.fire({
            icon: "warning",
            title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
            html: `
              <span style="font-size: 16px">
                Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
              </span>              
            `,
            showCancelButton: true,
            cancelButtonText: "Cek Ulang",
            confirmButtonText: "Lanjutkan & Submit"
          }).then(button => {
            if (button.isConfirmed) {
              const payload = { ...this.row,
                id_registrasi: this.$route.params.pageSlug
              }
              this.$parent.doSubmit({
                ...payload,
                type: typeSubmit
              })
            }
          })
        })
      }
    },
    computed: {
      row() {
        return this.$parent.row
      },
      mrValidation() {
        return this.$parent.mrValidation
      },
      Formatter() {
        return Formatter
      },

      isQuestionMateri(){
        return (this.row.materi||[]).find(dtMateri => dtMateri.value === "ADD_QUESTION")
      }
    },
    watch: {
      'isQuestionMateri'(v){
        if(!v) this.$set(this.row, 'question', null)
      }
    }
  }
</script>
